import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
} from "mdbreact";

import PageWrap from "../../components/PageWrap";
import { Link } from "react-router-dom";

class SSS extends Component {
  state = {
    collapseID: "collapse1",
  };

  toggleCollapse = (collapseID) => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  render() {
    const { collapseID } = this.state;
    return (
      <div>
        <Helmet>
          <title>Online Lisanslama - Sıkça Sorulan Sorular</title>
          <meta
            name="description"
            content="Online lisanslama sıkça sorulan sorular sayfası, lisanslama.org sıkça sorulan sorular sayfası, lisanslama.org sıkça sorulan sorular, lisanslama.org S.S.S."
          />
        </Helmet>
        <PageWrap
          title="Sıkça Sorulan Sorular"
          homeText="Anasayfa"
          pageName="Sıkça Sorulan Sorular"
        />
        <MDBContainer className="md-accordion my-5">
          <MDBCard className="mt-3">
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse1")}>
              Müzik lisansı nedir?
              <MDBIcon icon={collapseID === "collapse1" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse1" isOpen={collapseID}>
              <MDBCardBody>
                İşletmenizde müzik yayını yapıyorsanız hak sahiplerinden yazılı izin almanız
                gerekir. Müzik lisansı da MÜ-YAP repertuvarlarının kullanımı için alınması gereken
                bu izin belgesidir ve işletmenizde yaptığınız müziği yasal hale getirir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse2")}>
              Neden müzik lisansı almalıyım?
              <MDBIcon icon={collapseID === "collapse2" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse2" isOpen={collapseID}>
              <MDBCardBody>
                Bir işletmede yapılacak müzik yayını / kullanımı için izin almak yasal
                gerekliliktir. İzinsiz müzik kullanmak hak ihlalidir. Müzik lisansı alarak ayrıca
                müziği yaratanlara katkıda bulunur ve müziğin geleceği için bu insanları desteklemiş
                olursunuz. Böylece müzik kültürümüzün geliştirilmesine, müziği kaydedenlerin
                sevdiğiniz müziği size ulaştırmalarına devem etmelerini sağlarsınız.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse3")}>
              Müzik lisansı aldığımda hangi müzikleri yayınlayabilirim?
              <MDBIcon icon={collapseID === "collapse3" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse3" isOpen={collapseID}>
              <MDBCardBody>
                Müzik lisansına sahip olan bir işletme MÜ-YAP repertuvarında bulunan yerli ve
                yabancı milyonlarca parçanın yayınını yapabilir. Konseptinize uygun ve işletmenize
                değer katacak olan müzik tarzını belirlemekte özgür olacaksınız.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse4")}>
              Müzik lisansı için ne kadar ödemem gerekir?
              <MDBIcon icon={collapseID === "collapse4" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse4" isOpen={collapseID}>
              <MDBCardBody>
                Müzik lisansı bedeli iş yerinizin türüne, büyüklüğüne ve yerine göre değişiklik
                gösterir. Kesin olarak ne kadar ödemeniz gerektiğini sayfanın en üstünde bulunan
                LİSANS AL butonuna basarak görebilirsiniz.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse5")}>
              İşletmemde sadece TV veya radyo kullanıyorum. Neden lisans alayım?
              <MDBIcon icon={collapseID === "collapse5" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse5" isOpen={collapseID}>
              <MDBCardBody>
                İşletmenizde müzik yayınını hangi vasıta ile yaptığınız fark etmez, yine de yasal
                izninizi almanız gerekir. İşletmelerde müzik kullanımı / yayını Televizyon veya
                radyo kullanılmak suretiyle yapılsa bile bunu müşterilerinize ilettiğinizde de müzik
                lisansı alması gerekir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse6")}>
              Zaten TV platformunu para verip alıyorum. Neden lisans alayım?
              <MDBIcon icon={collapseID === "collapse6" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse6" isOpen={collapseID}>
              <MDBCardBody>
                İşletmenizde yayınını yaptığınız TV platformlarına ödediğiniz para, platformun
                yayınları size ulaştırması ve sizin o yayınları kullanabilmeniz içindir. O
                platformların hiçbiri size müzik yayını izni vermez / veremez. Bu durumu TV
                platformunuz ile de görüşebilirsiniz. Türkiye’de müzik yayınları ile alakalı
                izinleri sadece ilgili meslek birliklerinden alabilirsiniz.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse7")}>
              İşletmemde müzik yayınını internet üzerinden yapıyorum. Yine de izin almalı mıyım?
              <MDBIcon icon={collapseID === "collapse7" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse7" isOpen={collapseID}>
              <MDBCardBody>
                İşletmenizdeki müzik yayınını internet üzerinden youtube gibi platformlardan, Fizy
                veya Spotify gibi uygulamalardan da yapsanız yine de hak sahiplerini temsil eden
                meslek birliklerinden izin almanız gerekmektedir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse8")}>
              Müziği daha önce satın aldıysam, neden bir lisans ücreti ödemeliyim?
              <MDBIcon icon={collapseID === "collapse8" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse8" isOpen={collapseID}>
              <MDBCardBody>
                CD'den ya da dijital kaynaklardan yani internet üzerinden para ödeyerek satın
                aldığınız müzikler kişisel kullanımlarınız içindir. Ancak bu müziği kendi dinlemeniz
                dışında umuma iletirseniz, yani işletmenizde müzik yayını yapmakta kullanırsanız
                Yasalar gereği müzik lisansına yine de ihtiyacınız olacaktır.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse9")}>
              MÜ-YAP nedir?
              <MDBIcon icon={collapseID === "collapse9" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse9" isOpen={collapseID}>
              <MDBCardBody>
                MÜ-YAP 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre kurulmuş, T.C. Kültür ve
                Turizm Bakanlığı’na bağlı olarak müzik alanında faaliyet gösteren meslek birliğidir.
                MÜ-YAP, üyeleri olan tüm fonogram yapımcıları adına müzik lisansı verir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse11")}>
              Param nereye gidiyor?
              <MDBIcon icon={collapseID === "collapse11" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse11" isOpen={collapseID}>
              <MDBCardBody>
                Aldığınız müzik lisansı için ödediğiniz lisans bedeli MÜ-YAP tarafından kendi
                üyelerine dağıtılmaktadır. Meslek birliğimiz Bakanlığın idari ve mali denetimine
                tabidirler. Ödediğiniz lisans bedellerinin üyelerimize dağıtılması ile ilgili olarak
                da denetlenmektedir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse12")}>
              Lisans almadan müzik yayınına devam edersem ne olur?
              <MDBIcon icon={collapseID === "collapse12" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse12" isOpen={collapseID}>
              <MDBCardBody>
                İş yerinizde yaptığınız müzik kullanımları ve yayınları için lisans almak kanunen
                zorunludur. Müzik lisansı alınmadan müzik yayınlarına devam etmeniz kanuna aykırı
                hareket etmenize sebep olur. Bu durumda MÜ-YAP bütün yasal haklarını kullanarak
                işletmeniz için hukuki işlem başlatabilir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse13")}>
              Lisans alınmaması halinde hukuki yaptırımlar nedir?
              <MDBIcon icon={collapseID === "collapse13" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse13" isOpen={collapseID}>
              <MDBCardBody>
                MÜ-YAP meslek birlikleri, kendi müzik repertuvarlarının izinsiz kullanıldığının ve
                yayınlandığının tespiti halinde; izin bedelinin üç katına kadar tazminat talep
                etmekte ve Cumhuriyet Başsavcılıklarına şikâyet başvuruların yapmaktadırlar. Bu
                durumda da sorumlular hakkında 1 ila 5 yıla kadar hapis cezası veya karşılığı adli
                para cezası söz konusu olmaktadır.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse17")}>
              MÜ-YAP dışındaki meslek birlikleri ile sözleşmem var. Bu yeterli değil mi?
              <MDBIcon icon={collapseID === "collapse17" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse17" isOpen={collapseID}>
              <MDBCardBody>
                MÜ-YAP repertuvarını kullanacak tüm mekanlar bu yasal izinleri ancak MÜ-YAP
                alabilirler. Başka meslek birlikleri ile yapılan sözleşmeler, MÜ-YAP’ın
                repertuvarını kullanım iznini kapsamaz. MÜ-YAP repertuvarının kullanılması halinde
                başka meslek birliklerinden alınan izinler tek başına yeterli değildir. Bu konuyla
                ilgili TC. Kültür ve Turizm Bakanlığı Telif Hakları Genel Müdürlüğü’nün yazısını{" "}
                <Link to="/anasayfa/sikca-sorulan-sorular/bakanlik-yazisi" target="_blank">
                  buraya basarak
                </Link>{" "}
                görebilirsiniz.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse14")}>
              MÜ-YAP hakkında hangi merciden bilgi alabilirim?
              <MDBIcon icon={collapseID === "collapse14" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse14" isOpen={collapseID}>
              <MDBCardBody>
                MÜ-YAP meslek birliği hakkındaki tüm bilgileri T.C. Kültür ve Turizm Bakanlığı Telif
                Hakları Genel Müdürlüğü’nden (www.telifhaklari.gov.tr, 0.312.4706814) alabilirsiniz.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard>
            <MDBCollapseHeader
              tagClassName="d-flex justify-content-between"
              onClick={() => this.toggleCollapse("collapse16")}>
              İşletmeme hukuki işlem yapıldı, ne yapmalıyım?
              <MDBIcon icon={collapseID === "collapse16" ? "angle-up" : "angle-down"} />
            </MDBCollapseHeader>
            <MDBCollapse id="collapse16" isOpen={collapseID}>
              <MDBCardBody>
                İşletmenize meslek birliğimiz tarafından hukuki işlem yapılmışsa (delil tespiti
                ya da dava açılmışsa) lisanslama.org’dan alacağınız müzik lisansı, hukuki işleminizi
                ortadan kaldırmaya yetmeyecektir. Bu durumda sözleşme görüşmelerinizi merkezimiz ile
                yapmanız ve müzik lisansınızı bizlerle görüşerek almanız gerekmektedir.
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <h1 className="h3 mt-5">HALA SORUNUZ VARSA!</h1>
          <p>Lütfen bizi arayın 212.243 82 14</p>
        </MDBContainer>
      </div>
    );
  }
}

export default SSS;
