import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";

import PageWrap from "../../components/PageWrap";
import Timeline from "../../components/Timeline/Timeline";
import TimelineElement from "../../components/Timeline/TimelineElement";

const ApplicationProcess = () => (
  <div>
    <Helmet>
      <title>Online Lisanslama - Başvuru Süreci</title>
      <meta
        name="description"
        content="Online lisanslama başvuru süreci sayfası, lisanslama.org başvuru süreci sayfası, başvuru süreci hakkında daha fazla bilgi"
      />
    </Helmet>
    <PageWrap title="Başvuru Süreci" homeText="Anasayfa" pageName="Başvuru Süreci" />

    <MDBContainer fluid className="px-0">
      <MDBRow className="no-gutters">
        <MDBCol md="12">
          <Timeline
            title="Başvuru Süreci"
            description="Başvurunuzu yaptıktan sonra ne yapıyoruz?">
            <TimelineElement
              date="Birinci Adım"
              title="Lokasyon Bilgileriniz Alınır"
              description="İşletmenizin bulunduğu ili ve ilçeyi seçiyorsunuz."
            />
            <TimelineElement
              right
              date="İkinci Adım"
              title="İşletmenizin Türü Nedir?"
              description="İşletmenizin türünü ve metre karesini seçiyorsunuz. Eğer işletmeniz otel ise oda ve yıldız sayısını seçmeniz gerekmektedir."
            />
            <TimelineElement
              date="Üçüncü Adım"
              title="Tarifeye Bağlı Tutar Hesabı Yapılır"
              description="Vermiş olduğunuz bilgilere göre lisans bedeliniz, tarifemiz doğrultusunda hesaplanır ve size gösterilir. İş yeriniz için müzik lisansı almak isterseniz işleme devam etmelisiniz."
            />
            <TimelineElement
              right
              date="Dördüncü Adım"
              title="Cep Telefonu Doğrulaması"
              description="Güvenliğiniz amacıyla cep telefonunuza doğrulama kodu gelecektir. Gelen kodu ilgili alana girdikten sonra devam etmelisiniz."
            />
            <TimelineElement
              date="Beşinci Adım"
              title="İşletmenizin Bilgileri Alınır"
              description="Sözleşmenizin hazırlanabilmesi ve size fatura kesebilmemiz için işletmenizle ilgili bilgileri girmeniz gerekiyor."
            />
            <TimelineElement
              right
              date="Altıncı Adım"
              title="Ödeme Yapılır"
              description="Ödeme sayfasına yönlendirileceksiniz. Kredi kartınız ile ödemeniz gerçekleşecektir."
            />
            <TimelineElement
              last
              date="Son Olarak"
              title="Lisansınız Hazır!"
              description="Lisansınız hazır. Hem e-mailinize sözleşme metni hem de cep telefonunuza QR kodu geldi."
            />
          </Timeline>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

export default ApplicationProcess;
