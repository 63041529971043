import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

import ForgotPasswordWizard from "../../components/ForgotPassword";

class ForgotPassword extends Component {
  componentWillMount() {
    document.body.style.backgroundColor = "#002951";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div className="site-blocks-cover">
        <Helmet>
          <title>Online Lisanslama - Şifrenizi Mi Unuttunuz?</title>
          <meta
            name="description"
            content="Online lisanslama şifrenizi mi unuttunuz sayfası, lisanslama.org şifrenizi mi unuttunuz sayfası, lisanslama.org şifrenizi mi unuttunuz"
          />
        </Helmet>
        <MDBContainer>
          <MDBRow className="align-items-center justify-content-center">
            <MDBCol>
              <ForgotPasswordWizard />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default ForgotPassword;
