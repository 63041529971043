import { MDBCol, MDBRow, MDBTable, MDBTableBody } from "mdbreact";
import React from "react";

function ApplicationGeneralExplanationText() {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-1">
        VERİ SORUMLUSUNA BAŞVURU GENEL AÇIKLAMALAR
      </h3>
      <h4 className="h4 h4-responsive text-center mb-5">
        (KVKK 13’üncü ve 22’nci maddeler kapsamında)
      </h4>
      <h3 className="h3 h3-responsive">A. BAŞVURU İLE İLGİLİ HAKLARINIZ</h3>
      <p>
        Kanunun 11’ inci maddesinde belirtilen haklarınız kapsamında taleplerinizi, veri sorumlusu
        olan MÜ-YAP Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği (bundan sonra kısa
        <b>MÜ-YAP</b> olarak anılacaktır) ile MÜYORBİR Müzik Yorumcuları Meslek Birliği’ne (bundan
        sonra kısaca <b>'MÜYORBİR'</b> olarak anılacaktır) iletebilirsiniz. Online Lisanslama Birimi
        ile ilgili işlemlerde MÜ-YAP ve MÜYORBİR’in müşterek sorumluluğu vardır.
      </p>
      <h5 className="h5 h5-responsive">MÜ-YAP’a Başvuru İçin:</h5>
      <p>
        MÜ-YAP’ın{" "}
        <b>Kuloğlu Mah. Turnacıbaşı Cad. Saray Han. No:10 K:4 P.K. 34433 Beyoğlu/İstanbul</b>
        adresine yazılı olarak posta yoluyla veya{" "}
        <a href="mailto:muyap@mu-yap.org" target="_blank" rel="noopener noreferrer">
          muyap@mu-yap.org
        </a>{" "}
        kayıtlı elektronik posta adresine e-posta ile güvenli elektronik imza, mobil imza ya da veri
        sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik
        posta adresini kullanmak suretiyle veya 0212-292 46 17 nolu numaraya faks çekerek ya da
        başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla veri
        sorumlusuna iletebilirsiniz.
      </p>
      <h5 className="h5 h5-responsive">MÜYORBİR’e Başvuru İçin:</h5>
      <p>
        MÜYORBİR’in{" "}
        <b>Harbiye Mah. Cumhuriyet Cad. Dörtler Apt. No:42 K:1 Elmadağ-Şişli/İstanbul</b>
        adresine yazılı olarak posta yoluyla veya{" "}
        <a href="mailto:info@muyorbir.org.tr" target="_blank" rel="noopener noreferrer">
          info@muyorbir.org.tr
        </a>{" "}
        kayıtlı elektronik posta adresine e-posta ile güvenli elektronik imza, mobil imza ya da veri
        sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik
        posta adresini kullanmak suretiyle veya 0212 241 74 79 nolu numaraya faks çekerek ya da
        başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla veri
        sorumlusuna iletebilirsiniz.
      </p>
      <h6>KVKK m. 11 kapsamında haklarınız:</h6>
      <ol>
        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
          öğrenme,
        </li>
        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
        <li>
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
          isteme,
        </li>
        <li>
          7’ inci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok
          edilmesini isteme,
        </li>
        <li>
          KVKK 7. maddesi uyarınca (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
          verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
        </li>
        <li>
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
          kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
        </li>
        <li>
          Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
          giderilmesini talep etme, olarak düzenlenmiştir.
        </li>
      </ol>
      <h3 className="h3 h3-responsive">B. BAŞVURU USULÜ</h3>
      <p>
        KVKK m. 13 ve Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğin 5. maddesi
        gereğince başvuru usulü; yazılı olarak veya güvenli elektronik imza ya da daha önce meslek
        birliğimize bildirilen ve kayıtlı bulunan elektronik posta adresini kullanarak yapılmalıdır.
      </p>
      <p>
        Online Lisanslama Birimi hakkında, MÜ-YAP veya MÜYORBİR’e yapmış olduğunuz başvurunuz,
        tebliğ alındığı ya da tarafımıza ulaştığı tarihten itibaren en kısa süre içerisinde ve en
        geç 30 gün içinde yazılı olarak cevaplanacaktır. İşleminizin bir maliyet gerektirmesi
        halinde Kişisel Verilerin Korunması Kurulu tarafından belirlenen tarifedeki ücret alınır.
        Ücret tarifesi için www.kvkk.gov.tr adresini ziyaret edebilirsiniz.
      </p>
      <h3 className="h3 h3-responsive">
        C. BAŞVURUNUN YAPILACAĞI ADRES / KAYITLI ELEKTRONİK POSTA ADRESİ
      </h3>
      <p>
        Başvurunuzu, Kanunun 11 inci maddesinde belirtilen haklarınız kapsamında taleplerinizi, veri
        sorumlusu olan MÜ-YAP ve MÜYORBİR’e yapabilirsiniz.
      </p>
      <h5 className="h5 h5-responsive">Veri Sorumlularından MÜ-YAP’a başvurularınız için:</h5>
      <p>
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesinde yer alan başvuru formunu doldurmak suretiyle, Kuloğlu Mah.
        Turnacıbaşı Cad. Saray Han. No:10 K:4 P.K. 34433 Beyoğlu/İstanbul adresine yazılı olarak
        posta yoluyla veya{" "}
        <a href="mailto:muyap@mu-yap.org" target="_blank" rel="noopener noreferrer">
          muyap@mu-yap.org
        </a>{" "}
        kayıtlı elektronik posta adresine e-posta ile güvenli elektronik imza, mobil imza ya da veri
        sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik
        posta adresini kullanmak suretiyle veya 0212-292 46 17 nolu numaraya faks çekerek ya da
        başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla veri
        sorumlusuna iletiniz.
      </p>

      <h5 className="h5 h5-responsive">Veri Sorumlularından MÜYORBİR’e başvurularınız için:</h5>
      <p>
        <a
          rel="noopener noreferrer"
          href="https://www.lisanslama.org"
          target="_blank"
          style={{ textDecoration: "underline" }}>
          https://www.lisanslama.org
        </a>{" "}
        uzantılı internet sitesinde yer alan başvuru formunu doldurmak suretiyle, Harbiye Mah.
        Cumhuriyet Cad. Dörtler Apt. No:42 K:1 Elmadağ-Şişli/İSTANBUL adresine yazılı olarak posta
        yoluyla veya{" "}
        <a href="mailto:info@muyorbir.org.tr" target="_blank" rel="noopener noreferrer">
          info@muyorbir.org.tr
        </a>{" "}
        kayıtlı elektronik posta adresine e-posta ile güvenli elektronik imza, mobil imza ya da veri
        sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik
        posta adresini kullanmak suretiyle veya 0212 241 74 79 nolu numaraya faks çekerek ya da
        başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama vasıtasıyla veri
        sorumlusuna iletiniz.
      </p>
      <p>
        Veri Sorumluları MÜ-YAP ve MÜYORBİR’e ait yukarıda verilen adresler dışında yapılacak
        başvurular kabul edilmeyecektir.
      </p>

      <h3 className="h3 h3-responsive">D. BAŞVURU</h3>
      <MDBRow>
        <MDBCol md="5" sm="12">
          Ad Soyad
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="5" sm="12">
          T.C kimlik No / Pasaport Numarası/Mavi Kart No
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="5" sm="12">
          Adres
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="5" sm="12">
          KEP (E-POSTA)
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="5" sm="12">
          TALEP KONUSU
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="5" sm="12">
          EKTE YER ALAN BELGELER
        </MDBCol>
        <MDBCol md="7" sm="12">
          :{" "}
        </MDBCol>
      </MDBRow>

      <h3 className="h3 h3-responsive">E. BAŞVURUDA TALEP KONUSU HAKLAR</h3>
      <MDBTable responsive bordered>
        <MDBTableBody>
          <tr>
            <td style={{ width: "60%" }}>Kişisel Verilerim İşleniyor mu?</td>
            <td style={{ width: "30%" }}>KVKK m. 11/a</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel Verilerim İşleniyor ise bilgi verilmesini istiyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11 /b</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerim ne amaçla işleniyor ve işleme amacı doğrultusunda mı kullanılıyor?
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/c</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerim yurtiçinde ve yurtdışında kimlerle paylaşılıyor?
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/ç</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>Kişisel Verilerimin güncellenmesini talep ediyorum.</td>
            <td style={{ width: "30%" }}>KVKK m. 11/d</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin silinmesini ve anonim hale getirilmesini / yok edilmesini talep
              ediyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/e</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin silinmesini ve anonim hale getirilmesini / yok edilmesini talep
              ediyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/f</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
              suretiyle aleyhime bir sonuç doğmasına itiraz ediyorum. Analiz edilmesini istemiyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/g</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
        </MDBTableBody>
      </MDBTable>

      <p>
        <a href="/documents/basvuru-formu.doc" download target="_self">
          <span>Başvuru formunu indirmek için tıklayınız</span>
        </a>
      </p>
    </>
  );
}

export default React.memo(ApplicationGeneralExplanationText);
