import { MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";

import OlbAbout from "../../components/About";
import PageWrap from "../../components/PageWrap";
import { Statistic, StatisticItem } from "../../components/Statistic";

const About = () => (
  <div>
    <Helmet>
      <title>Online Lisanslama - Hakkımızda</title>
      <meta
        name="description"
        content="Online lisanslama hakkımızda sayfası, lisanslama.org hakkımızda sayfası, lisanslama.org hakkında daha fazla bilgi"
      />
    </Helmet>
    <PageWrap title="Hakkımızda" homeText="Anasayfa" pageName="Hakkımızda" />
    <OlbAbout
      className="bg-light"
      openDays="Pazartesi - Cuma"
      openHours="09:00 - 18:00"
      phone="+90 212 243 82 14"
      title="Hakkımızda"
      description="MÜ-YAP 5846 sayılı Fikir ve Sanat Eserleri kanununa göre kurulmuş meslek birliğidir. MÜ-YAP üyesi olan müzik yapımcı firmalarının haklarını koruyan meslek birlikleridir.

        Meslek birliğimiz, üyelerinin müzik repertuvarı ile ilgili olarak lisans vermeye

        Meslek birliğimizin tüm iş ve işlemleri Kültür ve Turizm Bakanlığı’nın idari ve mali denetimi altındadır."
    />
    <Statistic description="MÜ-YAP'tan müzik lisansı alarak, işletmesinde yasal müzik kullanan firma sayını ve güncel müziklerin kullanıldığı nokta sayısı">
      <StatisticItem number={4550} text="Lisanslı Firma" />
      <StatisticItem number={1234} text="Lisanslı Marka" />
      <StatisticItem number={9422} text="Lisanslı Nokta" />
    </Statistic>

    <MDBContainer className="mb-5">
      <MDBRow>
        <MDBCol md="12" className="mb-5">
          <h1
            className="h1 text-center font-weight-bold"
            style={{ color: "#153f59", fontSize: "50px", lineHeight: "1.2" }}>
            MÜ-YAP Firma Bilgileri
          </h1>
        </MDBCol>

        <MDBCol md="12">
          <MDBTable small bordered responsive className="mx-auto" style={{ width: "75%" }}>
            <MDBTableBody>
              <tr>
                <td className="font-weight-bold">Firma Adı</td>
                <td className="font-weight-bold">
                  MÜ-YAP BAĞLANTILI HAK SAHİBİ FON.YAP.MESLEK BİRLİĞİ
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold">Sokak</td>
                <td className="font-weight-bold">KULOĞLU MAHALLESİ</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Cadde-Konut No</td>
                <td className="font-weight-bold">TURNACIBAŞI CADDESİ NO:10/5</td>
              </tr>

              <tr>
                <td className="font-weight-bold">Posta Kodu</td>
                <td className="font-weight-bold">34433</td>
              </tr>
              <tr>
                <td className="font-weight-bold">İlçe</td>
                <td className="font-weight-bold">BEYOĞLU</td>
              </tr>
              <tr>
                <td className="font-weight-bold">İl</td>
                <td className="font-weight-bold">İSTANBUL</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Telefon</td>
                <td className="font-weight-bold">0212 292 46 13</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Faks</td>
                <td className="font-weight-bold">0212 292 46 17</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Email</td>
                <td className="font-weight-bold"></td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

export default About;
