import {
  MDBCol,
  MDBContainer,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from "mdbreact";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import PageWrap from "../../../components/PageWrap";
import {
  ApplicationFormText,
  ApplicationGeneralExplanationText,
  ClarificationTermText,
  CookiePolicyText,
  DisposalPolicyText,
  ProtectionOfPersonalDataText,
  TermOfUseText,
} from "../../../components/PrivacyTexts";

function ProtectionOfPersonalData() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabNameQuery = params.get("sekme") || "kisisel-verilerin-korunmasi-politikasi";
  const [activeItemJustified, setActiveItemJustified] = useState(tabNameQuery);

  const toggleTab = tab => () => {
    if (activeItemJustified !== tab) {
      setActiveItemJustified(tab);
    }
  };

  return (
    <>
      <Helmet>
        <title>Online Lisanslama - Kişisel Verilerin Korunması ve İşlenmesi Politikası</title>
        <meta
          name="description"
          content="Online lisanslama Kişisel Verilerin Korunması ve İşlenmesi Politikası, lisanslama.org Gizlilik, Kişisel Verilerin Korunması ve İşlenmesi Politikası"
        />
      </Helmet>
      <PageWrap
        title="KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI"
        homeText="Gizlilik"
        pageName="Kişisel Verilerin Korunması ve İşlenmesi Politikası"
      />

      <MDBContainer className="my-5 classic-tabs">
        <MDBRow>
          <MDBCol md="12">
            <MDBNav classicTabs color="blue-grey">
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "kisisel-verilerin-korunmasi-politikasi"}
                  onClick={toggleTab("kisisel-verilerin-korunmasi-politikasi")}
                  role="tab">
                  KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "aydinlatma-metni"}
                  onClick={toggleTab("aydinlatma-metni")}
                  role="tab">
                  AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "kullanim-kosullari"}
                  onClick={toggleTab("kullanim-kosullari")}
                  role="tab">
                  KULLANIM KOŞULLARI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "cerez-politikasi"}
                  onClick={toggleTab("cerez-politikasi")}
                  role="tab">
                  ÇEREZ POLİTİKASI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "imha-politikasi"}
                  onClick={toggleTab("imha-politikasi")}
                  role="tab">
                  KİŞİSEL VERİLERİ SAKLAMA VE İMHA POLİTİKASI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "basvuru-genel-aciklamasi"}
                  onClick={toggleTab("basvuru-genel-aciklamasi")}
                  role="tab">
                  VERİ SORUMLUSUNA BAŞVURU GENEL AÇIKLAMALARI
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={activeItemJustified === "basvuru-formu"}
                  onClick={toggleTab("basvuru-formu")}
                  role="tab">
                  BAŞVURU FORMU
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent className="card" activeItem={activeItemJustified}>
              <MDBTabPane tabId="kisisel-verilerin-korunmasi-politikasi" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <ProtectionOfPersonalDataText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="aydinlatma-metni" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <ClarificationTermText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="kullanim-kosullari" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <TermOfUseText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="cerez-politikasi" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <CookiePolicyText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="imha-politikasi" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <DisposalPolicyText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="basvuru-genel-aciklamasi" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <ApplicationGeneralExplanationText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="basvuru-formu" role="tabpanel">
                <MDBRow className="mt-2">
                  <MDBCol md="12" className="text-justify">
                    <ApplicationFormText />
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
            </MDBTabContent>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default ProtectionOfPersonalData;
