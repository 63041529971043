import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";

import PageWrap from "../../components/PageWrap";

const Tarrifs = () => (
  <div>
    <Helmet>
      <title>Online Lisanslama - Tarifeler</title>
      <meta
        name="description"
        content="Online lisanslama tarifeler sayfası, lisanslama.org tarifeler sayfası, lisanslama.org tarifeler hakkında bilgi"
      />
    </Helmet>
    <PageWrap title="Tarifeler" homeText="Anasayfa" pageName="Tarifeler" />

    <MDBContainer className="my-5">
      <MDBRow>
        <MDBCol md="12" className="d-flex flex-column">
        <a
            href="/documents/muyap-ve-muyorbir-2024-yili-ortak-tarifesi-ek-a.xlsx"
            download
            target="_self">
            <span>2024 YILI MÜ-YAP – MÜYORBİR EK A TARİFESİNİ İNDİRMEK İÇİN TIKLAYINIZ</span>
          </a>
          <a
            href="/documents/muyap-ve-muyorbir-2023-yili-ortak-tarifesi-ek-a.xlsx"
            download
            target="_self">
            <span>2023 YILI MÜ-YAP – MÜYORBİR EK A TARİFESİNİ İNDİRMEK İÇİN TIKLAYINIZ</span>
          </a>
          <a
            href="/documents/muyap-ve-muyorbir-2022-yili-ortak-tarifesi-ek-a.xlsx"
            download
            target="_self">
            <span>2022 YILI MÜ-YAP – MÜYORBİR EK A TARİFESİNİ İNDİRMEK İÇİN TIKLAYINIZ</span>
          </a>
          <a href="/documents/baglantili-haklar-2021-tarifesi-ek-a.xlsx" download target="_self">
            <span>2021 YILI MÜ-YAP – MÜYORBİR EK A TARİFESİNİ İNDİRMEK İÇİN TIKLAYINIZ</span>
          </a>
          <a href="/documents/baglantili-haklar-2020-tarifesi-ek-a.xlsx" download target="_self">
            <span>2020 YILI MÜ-YAP – MÜYORBİR EK A TARİFESİNİ İNDİRMEK İÇİN TIKLAYINIZ</span>
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
);

export default Tarrifs;
