import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  MDBAlert,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBMask,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBSpinner,
  MDBView,
} from "mdbreact";

import "./login.css";
import MaskInput from "../../components/Form/MaskInput";
import * as authActions from "../../redux/auth/actions";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [mobileNumber, setMobileNumber] = useState();
  const [password, setPassword] = useState();

  const authState = useSelector((state) => state.auth.auth);

  const onSubmit = (e) => {
    e.preventDefault();
    const cleanedMobileNumber = mobileNumber.replace(/\s/g, "");
    dispatch(
      authActions.authenticate({ mobileNumber: cleanedMobileNumber, password }, () => {
        history.push("/portal/dashboard");
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Online Lisanslama - Giriş Yap</title>
        <meta
          name="description"
          content="Online lisanslama kullanıcı giriş sayfası, lisanslama.org kullanıcı giriş sayfası, lisanslama.org kullanıcı girişi, giriş yap"
        />
      </Helmet>
      <div id="login">
        <MDBView src={require("../../assets/images/landing.jpg").default}>
          <MDBMask
            className="d-flex justify-content-center align-items-center"
            overlay="black-strong">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="10" lg="10" xl="7" sm="12" className="mt-5 mx-auto">
                  {authState.error && (
                    <MDBCol md="12">
                      <MDBAlert className="text-center" color="danger">
                        {authState.error}
                      </MDBAlert>
                    </MDBCol>
                  )}

                  <MDBModal
                    size="md"
                    cascading
                    className="modal-avatar"
                    isOpen={true}
                    backdrop={false}>
                    <form className="mx-3 grey-text" onSubmit={onSubmit}>
                      <MDBModalBody className="text-center mb-1">
                        <h2 className="mt-1 mb-2">Giriş Yapın</h2>

                        {authState.loading ? (
                          <div className="w-100 text-center">
                            <MDBSpinner big />
                          </div>
                        ) : (
                          <>
                            <MaskInput
                              name="mobileNumber"
                              mask="999 999 9999"
                              maskChar="_"
                              label="Telefon numaranız"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                            />

                            <MDBInput
                              name="password"
                              type="password"
                              label="Şifre"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </>
                        )}
                      </MDBModalBody>
                      <MDBModalFooter className="justify-content-center">
                        <MDBBtn type="submit" block color="primary">
                          Giriş Yap
                        </MDBBtn>

                        <Link
                          className="w-100 mt-1 text-center text-muted"
                          to="/giris/sifremi-unuttum">
                          Şifrenizi mi unuttunuz?
                        </Link>
                      </MDBModalFooter>
                    </form>
                  </MDBModal>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    </>
  );
}
