import React from "react";
import { Helmet } from "react-helmet";

import LicenseRequestArea from "../../components/LicenseRequest";
import PageWrap from "../../components/PageWrap";

const LicenseRequest = () => (
  <div>
    <Helmet>
      <title>Online Lisanslama - Lisans Al</title>
      <meta
        name="description"
        content="Online lisanslama lisans alma sayfası, lisanslama.org lisans alma sayfası, lisanslama.org lisans al"
      />
    </Helmet>
    <PageWrap homeText="Anasayfa" pageName="Lisans Başvurusu" title="Lisans Al" />
    <LicenseRequestArea />
  </div>
);

export default LicenseRequest;
