import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import PageWrap from "../../../components/PageWrap";
import { PrivacyStatementText } from "../../../components/PrivacyTexts";

function PrivacyStatement() {
  return (
    <Fragment>
      <Helmet>
        <title>Online Lisanslama - Kişisel Verilerin Korunması Politikası</title>
        <meta
          name="description"
          content="Online lisanslama Gizlilik Beyanı sayfası, lisanslama.org Gizlilik Beyanı, gizlilik beyanı"
        />
      </Helmet>
      <PageWrap title="GİZLİLİK BEYANI" homeText="Gizlilik" pageName="Gizlilik Beyanı" />

      <MDBContainer className="my-5">
        <MDBRow>
          <MDBCol md="12" className="text-justify">
            <PrivacyStatementText />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Fragment>
  );
}

export default PrivacyStatement;
