import { MDBCol } from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";

import { HomeSlider, HomeSliderItem } from "../../components/HomeSlider";
import { LicensingArea, LicensingAreaItem } from "../../components/LicensingArea";
import { Logo, LogoArea } from "../../components/Logo";
import { Statistic, StatisticItem } from "../../components/Statistic";
import About from "../../components/About";
import LicenseRequest from "../../components/LicenseRequest";
import Service from "../../components/Service/Service";
import ServiceItem from "../../components/Service/ServiceItem";
import TextWithHeader from "../../components/TextWithHeader";
import Timeline from "../../components/Timeline/Timeline";
import TimelineElement from "../../components/Timeline/TimelineElement";

// Logos
import bakanlik from "../../assets/images/logo/bakanlik-beyaz.png";
import muyap from "../../assets/images/logo/muyap-beyaz.png";
// Slider
import SliderImg1 from "../../assets/images/slider/1.jpg";
import SliderImg2 from "../../assets/images/slider/2.jpg";
import SliderImg3 from "../../assets/images/slider/3.jpg";
// Licensing Area
import uam from "../../assets/images/umuma-acik-mahaller.jpg";
import yayinKuruluslari from "../../assets/images/yayin-kuruluslari.jpg";
import dijitalKullanicilar from "../../assets/images/dijital-kullanicilar.jpg";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Online Lisanslama - Anasayfa</title>
        <meta
          name="description"
          content="Online lisanslama ana sayfası, lisanslama.org ana sayfası, lisanslama.org hakkında daha fazla bilgi"
        />
      </Helmet>
      <HomeSlider>
        <HomeSliderItem
          imagePath={SliderImg1}
          title="Müzik, İşinize Hareket Getirir"
          description="Müzik, bir işletmenin adı veya dekoru kadar önemli olabilir. Hem müşterilerinize hem de çalışanlarınıza daha güzel bir ortam için önemli bir rol oynar. Bu sebeple iş yerinizde güncel ve popüler müzikleri kullanmak işinizi hareketlendirir"
        />
        <HomeSliderItem
          imagePath={SliderImg2}
          title="Neden Lisans Alayım?"
          description="Müzik lisansı alarak, müziği yaratanlara katkıda bulunur ve müziğin geleceği için bu insanları desteklemiş olursunuz. Böylece icracı ve müziği kaydedenlerin sevdiğiniz müziği yapmaya devem etmesini sağlarsınız"
        />
        <HomeSliderItem
          imagePath={SliderImg3}
          title="Müzik lisansı"
          description="Umuma açık mekanlarda herhangi bir şekilde müzik kullanılması için izin almanız gerekir. Müzik lisansı, işletmenizde yayınladığınız müziğin yasal hale gelmesini sağlar. Müzik lisansının bedeli işletmenizin türüne ve büyüklüğüne göre değişir"
        />
      </HomeSlider>

      <LogoArea>
        <MDBCol md="6">
          <Logo imagePath={muyap} name="MÜ-YAP" />
        </MDBCol>
        <MDBCol md="6">
          <Logo
            imagePath={bakanlik}
            name="T.C. Kültür ve Turizm Bakanlığı"
            subText="Katkılarıyla."
          />
        </MDBCol>
        {/* <MDBCol md="12">
          <p
            className="text-justify text-white"
            style={{ fontSize: 14, fontWeight: "500" }}>
            Kültür ve Turizm Bakanlığı’nın desteği ile hazırlanan bu projenin içeriği Kültür ve
            Turizm Bakanlığı’nın görüş ya da tutumunu yansıtmamakta olup, içerik ile ilgili
            sorumluluk (destek alan) Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği
            (MÜ-YAP)’ne aittir.
          </p>
        </MDBCol> */}
      </LogoArea>

      <LicenseRequest />

      <About
        className="bg-light"
        openDays="Pazartesi - Cuma"
        openHours="09:00 - 18:00"
        phone="+90 212 243 82 14"
        title="Hakkımızda"
        description="MÜ-YAP 5846 sayılı Fikir ve Sanat Eserleri kanununa göre kurulmuş meslek birliğidir. MÜ-YAP üyesi olan müzik yapımcı firmalarının haklarını koruyan meslek birlikleridir.

        Meslek birliğimiz, üyelerinin müzik repertuvarı ile ilgili olarak lisans vermeye

        Meslek birliğimizin tüm iş ve işlemleri Kültür ve Turizm Bakanlığı’nın idari ve mali denetimi altındadır."
      />

      <Statistic description="MÜ-YAP'tan müzik lisansı alarak, işletmesinde yasal müzik kullanan firma sayını ve güncel müziklerin kullanıldığı nokta sayısı">
        <StatisticItem number={4550} text="Lisanslı Firma" />
        <StatisticItem number={1234} text="Lisanslı Marka" />
        <StatisticItem number={9422} text="Lisanslı Nokta" />
      </Statistic>

      <TextWithHeader title="Yasal Zorunluluk Hakkında" bgLight={false}>
        <p>
          5846 Sayılı Fikir ve Sanat Eserleri Kanunu gereğince Mü-yap Meslek Birliğinin
          repertuvarının umuma açık mahallerde ve yayın kuruluşlarının yayınlarında kullanılabilmesi
          / iletilebilmesi / yeniden iletilebilmesi için Meslek Birliğimizden yasıl izinler
          alınmalıdır.
        </p>
        <p>
          Bu yasal izinleri almadıkları belirlenen kullanıcılar hakkında tazminat davaları açılmakta
          1 ila 5 yıl hapis cezası veya karşılığı adli para cezası verilmesi talebi ile Cumhuriyet
          Başsavcılıklarına şikayet başvuruları yapılmaktadır.
        </p>
      </TextWithHeader>

      <Service title="Neler Yapıyoruz?">
        <MDBCol md="4" className="d-flex align-self-stretch ftco-animate text-center">
          <ServiceItem
            icon="fas fa-user-shield"
            title="YASAL LİSANSLAMA"
            description="Yasa ile kurulmuş meslek birlikleri olarak Müzik kullanan tüm işletmelere yasal izin veriyoruz"
          />
        </MDBCol>
        <MDBCol md="4" className="d-flex align-self-stretch ftco-animate text-center">
          <ServiceItem
            icon="fas fa-file-contract"
            title="YASAL SÜREÇ TAKİBİ"
            description="Yasal izin almadan müzik kullananlar hakkında Savcılık Makamlarına şikayet başvuruları yapıyoruz"
          />
        </MDBCol>
        <MDBCol md="4" className="d-flex align-self-stretch ftco-animate text-center">
          <ServiceItem
            icon="fas fa-record-vinyl"
            title="ÜYELERİMİZE DAĞITIM"
            description="Kullanıcıların ödedikleri izin bedellerini hak sahibi üyelerimize dağıtıyoruz."
          />
        </MDBCol>
      </Service>

      <LicensingArea
        title="Lisans Alanları"
        description="Hangi alanlarda lisans yapıyoruz?"
        bgLight>
        <LicensingAreaItem
          title="Yayın Kuruluşları"
          items={["Televizyon", "Radyo"]}
          image={yayinKuruluslari}
        />
        <LicensingAreaItem
          right
          title="Dijital Kullanıcılar ve Diğer Kuruluşlar"
          items={["İnternet", "Mobil"]}
          image={dijitalKullanicilar}
        />
        <LicensingAreaItem
          column="col-md-12 col-lg-12"
          title="Umuma Açık Mahaller"
          items={[
            "Konaklama Tesisleri",
            "Yeme - İçme - Eğlence Tesisleri",
            "Sağlık ve Spor Merkezleri",
            "Kongre, Sergi ve Gösteri Alanları",
            "Rekreasyon Tesisleri",
            "Özel Tesisler",
            "Ticaret Merkezleri",
            "Terminaller",
            "Eğitim ve Öğretim Kurumları",
            "50 m2 den Büyük Diğer Umuma Açık Mahaller",
            "Diğer Kullanımlar",
          ]}
          image={uam}
        />
      </LicensingArea>

      <Timeline title="Başvuru Süreci" description="Başvurunuzu yaptıktan sonra ne yapıyoruz?">
        <TimelineElement
          date="Birinci Adım"
          title="Lokasyon Bilgileriniz Alınır"
          description="İşletmenizin bulunduğu ili ve ilçeyi seçiyorsunuz."
        />
        <TimelineElement
          right
          date="İkinci Adım"
          title="İşletmenizin Türü Nedir?"
          description="İşletmenizin türünü ve metre karesini seçiyorsunuz. Eğer işletmeniz otel ise oda ve yıldız sayısını seçmeniz gerekmektedir."
        />
        <TimelineElement
          date="Üçüncü Adım"
          title="Tarifeye Bağlı Tutar Hesabı Yapılır"
          description="Vermiş olduğunuz bilgilere göre lisans bedeliniz, tarifemiz doğrultusunda hesaplanır ve size gösterilir. İş yeriniz için müzik lisansı almak isterseniz işleme devam etmelisiniz."
        />
        <TimelineElement
          right
          date="Dördüncü Adım"
          title="Cep Telefonu Doğrulaması"
          description="Güvenliğiniz amacıyla cep telefonunuza doğrulama kodu gelecektir. Gelen kodu ilgili alana girdikten sonra devam etmelisiniz."
        />
        <TimelineElement
          date="Beşinci Adım"
          title="İşletmenizin Bilgileri Alınır"
          description="Sözleşmenizin hazırlanabilmesi ve size fatura kesebilmemiz için işletmenizle ilgili bilgileri girmeniz gerekiyor."
        />
        <TimelineElement
          right
          date="Altıncı Adım"
          title="Ödeme Yapılır"
          description="Ödeme sayfasına yönlendirileceksiniz. Kredi kartınız ile ödemeniz gerçekleşecektir."
        />
        <TimelineElement
          last
          date="Son Olarak"
          title="Lisansınız Hazır!"
          description="Lisansınız hazır. Hem e-mailinize sözleşme metni hem de cep telefonunuza QR kodu geldi."
        />
      </Timeline>
    </div>
  );
};

export default Home;
